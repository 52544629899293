import React,{ useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import { IBasic } from './store/chat/types';

import { v4 as uuidv4 } from 'uuid';

import { Button } from 'reactstrap';

import axios from './axios';

//import { model2ICard } from './InitScript';

import { Input, Label } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

interface IProps {
	//	cards: ICard[];
	//	customers: ICustomer[],
	dbUpdate: typeof dbUpdate;
}

const classes=require('./Main.module.scss');

const Main: React.FC<IProps> = ({
	//cards,
	//customers,
	dbUpdate,
}) => {
	/*
	const newCard = () => {
		//dbUpdate({cards:[...cards,

		const x:ICard={
			id:uuidv4(),
			header:"Uusi",
			details:"",
			amount:0,
			stars:1,
			time:new Date(),
			status:ECardStatus.NEW,
		}
		//]})
		
		updateCard(x,cards,-1)
	}

	const updateCard = (data:ICard,i:ICard[],index:number) => {
		const data2send:any={
			id:data.id,
			name:data.header,
			customer:data.customer && data.customer.id,
			attrs:{
				details:data.details,
				amount:data.amount,
				stars:data.stars,
				time:data.time,
				dueDate:data.dueDate,
				status:data.status,
				color:data.color,
			},
		}

		axios.post("getdata/cards/",{model:"cards",data:data2send}).then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const ob={attrs:{},...resp.data}
					const c=model2ICard(ob,customers);

				if(index!==-1) {
					i.splice(index,1);
				}
				i.push(c)
				dbUpdate({cards:[...i]})
  	  }
  	});
	}

	const current = (d:number) => {
		return (d).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')+"€";
	}


	const [sortedCards, setSortedCards]=useState<{[id:string]:any}>({
		[ECardStatus.NEW]:[],
		[ECardStatus.CONTACTED]:[],
		[ECardStatus.OFFER]:[],
		[ECardStatus.WON]:[],
	}) 

	useEffect(()=>{
		console.log("UPDATE");
		setSortedCards(cards.reduce((resp:{[id:string]:ICard[]},ob:ICard)=>{
			const os=ob.status || ECardStatus.NEW
			resp[os]=[...resp[os],ob]
		
			return resp
		},{
			[ECardStatus.NEW]:[],
			[ECardStatus.CONTACTED]:[],
			[ECardStatus.OFFER]:[],
			[ECardStatus.WON]:[],
		}))
	},[cards]);

	const calcTotal = (items:ICard[]) => {
		return (items || []).reduce((resp:number,ob:ICard)=>resp+ob.amount,0)
	}
	*/

	return (<div>

		<div className={classes.block}>
			<Label>Nimi</Label>
			<Input></Input>

			<Label>Katuosoite</Label>
			<Input></Input>
			<Label>Postinumero</Label>
			<Input></Input>
			<Label>Postitoimipaikka</Label>
			<Input></Input>
			<Label>IBAN</Label>
			<Input></Input>
			<Label>Sähköposti</Label>
			<Input></Input>
		</div>

		<div className={classes.block}>
			<Label>Kesto ennen lähtöä</Label>
			<InputGroup>
        <Input />
        <InputGroupAddon addonType="append">
					<InputGroupText>min</InputGroupText>
        </InputGroupAddon>
			</InputGroup>

			<Label>Kesto ennen tapahtumaa</Label>
			<InputGroup>
        <Input />
        <InputGroupAddon addonType="append">
					<InputGroupText>min</InputGroupText>
        </InputGroupAddon>
			</InputGroup>

			<Label>Kesto tapahtuman jälkeen</Label>
			<InputGroup>
        <Input />
        <InputGroupAddon addonType="append">
					<InputGroupText>min</InputGroupText>
        </InputGroupAddon>
			</InputGroup>

			<Label>Kesto paluun jälkeen</Label>
			<InputGroup>
        <Input />
        <InputGroupAddon addonType="append">
					<InputGroupText>min</InputGroupText>
        </InputGroupAddon>
      </InputGroup>

		</div>


		<div className={classes.bottombtn}>
			<Button color="primary" onClick={()=>console.log("newCard")}><i className="fas fa-plus"/> Uusi kortti</Button>
		</div>

		</div>);
}

const mapStateToProps = (state: AppState) => ({
	//	cards: state.chat.cards,
	//	customers: state.chat.customers,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Main);

