import {
  ChatState,
	DB_INITIALIZE,
	DB_UPDATE,
  ChatActionTypes
} from "./types";

//const now=new Date();

export const initialState: ChatState = {
	loggedIn:false,

	basic:null,
	duration:null,
	events:[],
	measurements:[],
};


const saveState = (state:ChatState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('db', serializedState);
  } catch {
  }
}

export function chatReducer(
  state = initialState,
  action: ChatActionTypes
): ChatState {
	let retval=null;
  switch (action.type) {
		case DB_UPDATE:
			retval={
				...state,
				...action.payload,
			}
			break;

     case DB_INITIALIZE:
        return {
          ...initialState,
          ...action.state,
        }
    default:
  }

	if(retval) {
    saveState(retval)
    return retval
  }
  return state
}

