export interface IAddress {
	id:string;
	street:string;
	zipcode:string;
	city:string;
}

export interface IBasic {
	id:string;
	name:string;
	address:IAddress;
	iban?:string;
	email?:string;
}
/*
export interface IDefault {
	id:string;
	invoiceTemplate:string;
}

export interface IOrganization {
	id:string;
	name:string;
	businessId:string;
	address:IAddress;
	email?:string;
	invoiceTemplate?:string;
}

export interface IProject {
	id:string;
	name:string;
	sourceCalendar:string;
	targetCalendar?:string;
}
*/
export interface IDuration {
	id:string;
	minBeforeLeave:number;
	minBeforeEvent:number;
	minAfterEvent:number;
	minAfterReturn:number;
}

export interface IEvent {
	id:string;
	name:string;
	address:IAddress;
	startTime:Date;
	endTime:Date;
}

export interface IMeasurement {
	startAddress:IAddress;
	endAddress:IAddress;
	startTime:Date;
	endTime:Date;
}

//======================================================
//
export interface ChatState {
	loggedIn: boolean,
	basic:IBasic | null,
	duration:IDuration | null,
	events:IEvent[],
	measurements:IMeasurement[],
}

export const DB_INITIALIZE = 'DB_INITIALIZE'

export const DB_UPDATE = 'DB_UPDATE'

interface DbUpdateAction {
	type: typeof DB_UPDATE
	payload: ChatState
}

interface DbInitializeAction {
  type: typeof DB_INITIALIZE
	state: ChatState
}

export type ChatActionTypes = DbInitializeAction | DbUpdateAction
