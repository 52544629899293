import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

import configureStore from "./store";

import { dbInitialize, dbUpdate } from './store/chat/actions';

import { AppLanguage } from './i18n/const';

import './date.extensions';

//import { ICard } from './store/chat/types';

import axios from './axios';

//import { connectToChannels } from './store/chat/actions';

const store = configureStore();
store.dispatch(dbInitialize());

//store.dispatch(connectToChannels("mpyhtyma"))
//

/*
const loadCards = () => {
		axios.get("getdata?model=cards").then((resp)=>{
  	  if(resp.data.status==="auth") {
				store.dispatch(dbUpdate({loggedIn:false}));
  	  }
			else {
				const tc:ICard[]=[]
				Object.values(resp.data).map((ob:any)=>{				
					const c:ICard={
						id:ob.id,
						header:ob.name,
						details:ob.attrs.details,
						amount:ob.attrs.amount,
						stars:ob.attrs.stars,
						time:ob.attrs.time,
						dueDate:ob.attrs.dueDate,
						status:ob.attrs.status,
						//						tasks:ob.attrs.tasks,
						color:ob.attrs.color,
					}

					if(ob.customer_id) {

						console.log("customer",ob.customer_id)
					}

					tc.push(c)
				})
				console.log(tc)
				store.dispatch(dbUpdate({cards:tc}))
  	  }
  	});
	}

loadCards()
 */

const language=navigator.languages.reduce((resp,ob)=>{
	const lang=ob.split(/-|_/)[0] as AppLanguage;
	if(resp==="" && Object.values(AppLanguage).includes(lang)) {
		resp=lang;
	}
	return resp
},"") || "fi"

ReactDOM.render(<Provider store={store}>	
		{/*<React.StrictMode>*/}
			<App defaultLanguage={language as AppLanguage || AppLanguage.Suomi}/>
		{/*</React.StrictMode>*/}
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
