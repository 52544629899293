import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


//import moment from 'moment-timezone';

//const hostname = window && window.location && window.location.hostname;

const hostname = window && window.location && window.location.hostname;

let apiPath = '/';
//let withCredentials = false;

if(hostname === 'localhost') {
    apiPath = 'http://localhost:8001/';
//		withCredentials=true;
}

const instance = axios.create({
    baseURL: apiPath,
//    withCredentials: withCredentials
});

instance.interceptors.request.use(config => {
 //   const email = localStorage.getItem('email');
 //   const accessToken = localStorage.getItem('accessToken');
 //   config.headers['X-Auth-Email'] = email ? email : '';
 //   config.headers['X-Auth-AccessToken'] = accessToken ? accessToken : '';
 //   config.headers['Timezone'] = moment.tz.guess();
    return config;
});


instance.interceptors.response.use(config => config, ({ response }) => {
 //   if(!response || response.status === 500 || response.status === 405) {
 //       response.data = 'Server error! Please contact support.';
 //   }
    return Promise.reject({ response });
});

export default instance;
